import {Component} from 'react';

import './MainView.scss';
import Footer from '../../Components/Footer/Footer';
import Header from '../../Components/Header/Header';
import Content from '../Content/Content';
import DownloadFile from '../../assets/files/MINESEC_LAB_Version_1.0.zip';



class MainView extends Component {

    render() {
        return <div className='MainView container'>
            <div className="MainView--main">

                <div className="stock-ticker scroller--size">
                <ul>
                  <li className="minus">
                    <span className="company">Discover our innovative new educational app <span className='color-red'>VIRTUAL LABORATORY</span>, designed to enhance your practical learning experience, you can <a href={DownloadFile} download='VIRTUAL_LAB_Version_1.0.zip'>Download</a> it now</span>
                  </li>
                  <li className="minus">
                    <span className="company">Discover our innovative new educational app <span className='color-red'>VIRTUAL LABORATORY</span>, designed to enhance your practical learning experience, you can <a href={DownloadFile} download='VIRTUAL_LAB_Version_1.0.zip'>Download</a> it now</span>
                  </li>
                </ul>
              
                <ul aria-hidden="true">
                  <li className="minus">
                    <span className="company">Découvrez notre nouvelle application éducative innovante <span className='color-red'>LABORATOIRE VIRTUEL</span>, conçue pour améliorer votre expérience pratique. Vous pouvez la <a href={DownloadFile} download='VIRTUAL_LAB_Version_1.0.zip'>Télécharger</a> maintenant</span>
                  </li>
                </ul>
              </div>
                <Header/>
                <Content/>
                <Footer/>
            </div>
        </div>
    }
}

export default MainView;
